import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import HeaderTab from "../components/Header";
import FooterMain from "../components/Footer";
import { Box } from "@material-ui/core";
import { API } from "../commons/commonMethods";
import "../assets/css/styles.css";
import "./css/OurPeople.css";

export default function OurPeople() {
  const [isLoading, setIsLoading] = useState(false);
  const [ourPeople, setOurPeople] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      API()
        .get("/ourPeople")
        .then((res) => {
          setOurPeople(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const peopleList = useMemo(() => {
    return ourPeople.map((item, index) => {
      return (
        <Box className="people-item">
          <Link key={index} to={`/profile/${item.id}`}>
            <img
              src={require(`../assets/images/our-people/${item.imagePath}`)}
              alt={item.name}
              // alt={item.position}
            />
            <div className="title">{item.name}</div>
            <div className="position">
              <center>{item.position}</center>
            </div>
          </Link>
        </Box>
      );
    });
  }, [ourPeople]);

  return (
    <React.Fragment>
      <div className="background">
        <HeaderTab />
        <div className="container main-box people">
          <Box className="people-grid">
            {isLoading ? <div>Loading ...</div> : peopleList}
          </Box>
        </div>
        <FooterMain />
      </div>
    </React.Fragment>
  );
}
