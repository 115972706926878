import React from 'react';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import Clear from '@material-ui/icons/Clear';
import { isMobile, isBrowser } from 'react-device-detect';
import '../assets/css/styles.css';
import './css/Services.css';
import DigitalStrategy from '../assets/images/Icons/DigitalStrategy.png';
import Asset from '../assets/images/Icons/Asset.png';
import DataManagement from '../assets/images/Icons/DataManagement.png';
import IntegratedBusiness from '../assets/images/Icons/IntegratedBusiness.png';
import SystemIntegration from '../assets/images/Icons/SystemIntegration.png';
import MachineLearning from '../assets/images/Icons/machine-learning-ai.png';

const content = [
  {
    title: 'Reliable \n Capital Project \n Execution',
    textTitle:'Reliable Capital Project Execution',
    link: '/capitalProjects',
    text:
      'The information systems supporting projects are split across multiple organisations and information flow between systems is usually inadequate. Without reliable information readily available, productivity of the overall supply chain is reduced. Poor productivity ultimately leads to rework as well as poorly utilised labour which on an industrial mega project rapidly builds into significant cost and schedule overruns. The Cravern Group works with owners to help build capability in Building Information Management (BIM), Advanced Work Packaging (AWP) and supporting information systems, ensuring that a project has all the elements required to bring together an integrated project team.',
  },
  {
    title: 'Smooth \n Operational \n Readiness',
    textTitle:'Smooth Operational Readiness',
    link: '/operationalReadiness',
    text:
      'During project execution it is typical that operational readiness takes a second priority to construction activities. Yet, NPV loss through slow ramp up can be significant, and for some businesses it is the difference between a financially sound operating asset, and an economic disaster. Cravern specialises in helping your operation be ready for day one operations. Our operational readiness framework will ensure you have a fully operational management system in place when operations begin. Having the processes, systems and information readily available will help your organisation commission, and ramp up your plant with an organised, deliberate approach, ensuring you can rapidly negotiate the challenges of ramp up with the information required to meet nameplate capacity as quickly as your business case demands.',
  },
  {
    title: 'Improving \n Operational \n Performance',
    textTitle:'Improving Operational Performance',
    link: '/operations',
    text: 
    'Business leadership demand improves operational productivity but the information required to support business decisions is hidden within siloed systems. Even when information can be found, it is often unreliable and consequently technology enabled improvement is difficult to achieve. The successful leveraging of information to drive sustainable business advantage requires improving both organizational processes and supporting technology systems. This must be coupled with a culture of continued improvement and alignment driven across the operation.',
  },
];

export function className(index) {
  switch (index) {
    case 0:
      return 'first';
    case 1:
      return 'second';
    case 2:
      return 'third';
    case 3:
      return 'fourth';
    default:
      return '';
  }
}

export function contents(contentList, clickIndex, setClickIndex) {
  return contentList.map((item, index) => {
    return (
      <Box className="service-box" key={`service-${index}`}>
        <div className={`services-type ${className(index)}`}>
          <div
            onClick={() => isMobile && setClickIndex(index + 1)}
            className={`${isMobile ? 'mobileBoxText' : ''} ${isBrowser ? 'boxText' : ''} ${clickIndex === index + 1 ? 'boxTextClick' : ''}`}
          >
            {item.title}
          </div>
          <div className={`${isMobile ? 'mobileOverlay' : ''}${isBrowser ? 'overlay' : ''} ${clickIndex === index + 1 ? 'boxTextClick' : ''}`}>
            {isMobile && <Clear className="clear" onClick={() => setClickIndex(0)} />}
            <div className="textTitle">{item.textTitle}</div>
            <div className="text">{item.text}</div>
            
            
            
          </div>
        </div>
      </Box>
    );
  });
}

function contentsWithLink(contentList) {
  return contentList.map((item, index) => {
    return (
      <Box className="service-box" key={`service-${index}`}>
        <Link to={item.link}>
          <div className={`services-type ${className(index)}`}>
            <div className={`${isMobile ? 'mobileBoxText' : ''} ${isBrowser ? 'boxText' : ''}`}>{item.title}</div>
            <div className={`${isMobile ? 'mobileOverlay' : ''}${isBrowser ? 'overlay' : ''}`}>
            
              <div className="textTitle">{item.textTitle}</div>
              <div className="text">{item.text}</div>

              
              
            </div>
          </div>
        </Link>
      </Box>
    );
  });
}

function MarketingBox({ children }) {
  return (
    <Box flex="1 1 33%" minWidth="300px" display="flex" alignItems="center" flexWrap="nowrap" height="calc(50px + 10vh)">
      {children}
    </Box>
  );
}

export default function Services() {
  return (
    <div className="services-home">
      <Box className="service-flex">{contentsWithLink(content)}</Box>

      <div className="container">
        <div className="description">Technology driven improvement</div>
      </div>

      <Box className="marketing" display="flex" flexWrap="wrap">
        <MarketingBox>
          <div className="marketingImg">
            <img src={DigitalStrategy} />
          </div>
          <div className="marketingText">
            <span>Digital Strategy & Advisory</span>
          </div>
        </MarketingBox>
        <MarketingBox>
          <div className="marketingImg">
            <img src={SystemIntegration} />
          </div>
          <div className="marketingText">
            <span>System Integration & Automation</span>
          </div>
        </MarketingBox>
        <MarketingBox>
          <div className="marketingImg">
            <img src={IntegratedBusiness} />
          </div>
          <div className="marketingText">
            <span>Integrated Business Processes</span>
          </div>
        </MarketingBox>
        <MarketingBox>
          <div className="marketingImg">
            <img src={DataManagement} />
          </div>
          <div className="marketingText">
            <span>Data & Information Management</span>
          </div>
        </MarketingBox>
        <MarketingBox>
          <div className="marketingImg">
            <img src={Asset} />
          </div>
          <div className="marketingText">
            <span>Asset Reliability Monitoring</span>
          </div>
        </MarketingBox>
        <MarketingBox>
          <div className="marketingImg">
            <img src={MachineLearning} />
          </div>
          <div className="marketingText">
            <span>Machine Learning & AI</span>
          </div>
        </MarketingBox>
      </Box>
    </div>
  );
}
