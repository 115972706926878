import React, { useEffect, useState } from "react";
import { IoTimeSharp, IoCalendarSharp, IoLocationSharp } from "react-icons/io5";
import Box from "@material-ui/core/Box";
import HeaderTab from "../../components/Header";
import FooterMain from "../../components/Footer";
import { Link } from "react-router-dom";
import { API } from "../../commons/commonMethods";
import { contentType } from "../../commons/enum";
import "../../assets/css/styles.css";
import "./../css/Careers.css";
import DigitalStrategy from "../../assets/images/Icons/CreativityandInnovation.png";
import Asset from "../../assets/images/Icons/TrustandEmpowerment.png";
import DataManagement from "../../assets/images/Icons/CollaborationandTeamwork.png";
import IntegratedBusiness from "../../assets/images/Icons/Work-LifeBalanceandFamily.png";
import SystemIntegration from "../../assets/images/Icons/HonestyandIntegrity.png";
import MachineLearning from "../../assets/images/Icons/AspirationandPersonalGrowth.png";
import styled from "styled-components";
function MarketingBox({ children }) {
  return (
    <Box
      flex="1 1 33%"
      minWidth="300px"
      display="flex"
      alignItems="center"
      flexWrap="nowrap"
      height="calc(50px + 10vh)"
    >
      {children}
    </Box>
  );
}
export default function VacanciesPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [careers, setCareers] = useState([]);

  const getData = async () => {
    try {
      setIsLoading(true);
      API()
        .get(`/${contentType.CAREER}`)
        .then((res) => {
          setCareers(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const Wrapper = styled.div`
    padding: 5px;
    display: flex;
    flex-direction: row;
    border: 1px solid rgba(39, 62, 94, 0.2);
    border-radius: 5px;
    box-shadow: 0px 4px 6px 2px rgba(39, 62, 94, 0.6);
    margin-bottom: 30px;
  `;

  const StyledLink = styled(Link)`
    margin-bottom: 0em;
  `;

  const StyledBox0 = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 1.3em;
  `;

  const StyledBox = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3em;
  `;

  const StyledBox1 = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 20px;
    font-size: 1.3em;
  `;

  const careerList = (list) => {
    return list
      .filter((c) => c.status == "active")
      .map((career, index) => {
        return (
          <StyledLink to={`/vacancy/${career.id}`}>
            <Wrapper>
              <StyledBox0
                className="jobType"
                minWidth="260px"
                flex="1 1 28%"
                height="7vh"
                pl={2}
                py={1}
              >
                <span>{career.title}</span>
              </StyledBox0>
              <StyledBox
                className="location"
                minWidth="260px"
                flex="1 1 28%"
                height="7vh"
                pl={2}
                py={1}
              >
                <IoLocationSharp />
                <span>{career.location}</span>
              </StyledBox>
              <StyledBox
                className="category"
                minWidth="260px"
                flex="1 1 28%"
                height="7vh"
                pl={2}
                py={1}
              >
                <IoTimeSharp />
                <span>{career.category}</span>
              </StyledBox>
              <StyledBox1
                className="date"
                minWidth="260px"
                flex="1 1 28%"
                height="7vh"
                pl={2}
                py={1}
              >
                <IoCalendarSharp />
                <span>
                  {"Expires at " +
                    new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                    }).format(
                      new Date(
                        career.expireDate ? career.expireDate : new Date()
                      )
                    )}
                </span>
              </StyledBox1>
            </Wrapper>
          </StyledLink>
        );
      });
  };

  return (
    <React.Fragment>
      <div className="background">
        <HeaderTab />

        <div className="container careers">
          {isLoading ? (
            <div>Loading ...</div>
          ) : (
            <React.Fragment>
              <div className="header">
                <h1>Join the Cravern Team</h1>

                <Box className="marketing" display="flex" flexWrap="wrap">
                  <MarketingBox>
                    <div className="marketingImg">
                      <img src={DigitalStrategy} />
                    </div>
                    <div className="marketingText">
                      <span>Creativity and Innovation</span>
                    </div>
                  </MarketingBox>
                  <MarketingBox>
                    <div className="marketingImg">
                      <img src={SystemIntegration} />
                    </div>
                    <div className="marketingText">
                      <span>Honesty and Integrity</span>
                    </div>
                  </MarketingBox>
                  <MarketingBox>
                    <div className="marketingImg">
                      <img src={IntegratedBusiness} />
                    </div>
                    <div className="marketingText">
                      <span>Work-Life Balance and Family</span>
                    </div>
                  </MarketingBox>
                  <MarketingBox>
                    <div className="marketingImg">
                      <img src={DataManagement} />
                    </div>
                    <div className="marketingText">
                      <span>Collaboration and Teamwork</span>
                    </div>
                  </MarketingBox>
                  <MarketingBox>
                    <div className="marketingImg">
                      <img src={Asset} />
                    </div>
                    <div className="marketingText">
                      <span>Trust and Empowerment</span>
                    </div>
                  </MarketingBox>
                  <MarketingBox>
                    <div className="marketingImg">
                      <img src={MachineLearning} />
                    </div>
                    <div className="marketingText">
                      <span>Aspiration and Personal Growth</span>
                    </div>
                  </MarketingBox>
                </Box>
              </div>
              <div className="main-box" style={{ minHeight: 1200 }}>
                <h2 className="title">
                  <span style={{ fontSize: "1em" }}>Current vacancies</span>
                </h2>
                <div className="content">
                  Below are the current vacancies at the Cravern Group. If you
                  can’t see what you’re looking for, email your CV to
                  admin@cravern.com and we’ll let you know when something
                  suitable becomes available.
                </div>
                <div className="list">{careerList(careers)}</div>
              </div>
            </React.Fragment>
          )}
        </div>
        <FooterMain />
      </div>
    </React.Fragment>
  );
}
