import axios from "axios";
import { useLocation } from "react-router-dom";
import { getApiUrl } from "../config";

export const API = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Basic ${btoa("cravern:cravern")}`,
  };

  var url = getApiUrl();
  return axios.create({
    baseURL: url,
    timeout: 25000,
    headers: headers,
  });
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
