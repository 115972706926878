import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderTab from "../components/Header";
import FooterMain from "../components/Footer";
import { API } from "../commons/commonMethods";
import { contentType } from "../commons/enum";
import "../assets/css/styles.css";
import "./css/AllPodcasts.css";

export default function AllPodcasts() {
  const [isLoading, setIsLoading] = useState(false);
  const [podcasts, setPodcasts] = useState([]);

  const getData = async () => {
    try {
      setIsLoading(true);
      API()
        .get(`/${contentType.PODCAST}`)
        .then((res) => {
          setPodcasts(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const guestsName = (pCast) => {
    return pCast.guests && pCast.guests.length > 0
      ? pCast.guests.map((guest) => guest.name).join(" & ")
      : "";
  };

  const podcastList = (list) => {
    return list.map((podcast, index) => {
      return (
        <article key={index}>
          <Link to={`/podcast/${podcast.id}`}>
            <section className="title">{podcast.title}</section>
          </Link>
          <section className="detail">
            {guestsName(podcast)}
            <span> &#124; </span>
            {new Intl.DateTimeFormat("en-AU", {
              year: "numeric",
              month: "long",
              day: "2-digit",
            }).format(
              new Date(podcast.date ? new Date(podcast.date) : new Date())
            )}
            <span> &#124; </span> {podcast.length}
          </section>
        </article>
      );
    });
  };

  return (
    <React.Fragment>
      <div className="background">
        <HeaderTab />
        <div className="container main-box all-podcasts">
          <div className="main-title">PODCASTS</div>
          {isLoading ? <div>Loading ...</div> : podcastList(podcasts)}
        </div>
        <FooterMain />
      </div>
    </React.Fragment>
  );
}
