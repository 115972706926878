import { Route, Switch, BrowserRouter } from "react-router-dom";
import React, { useEffect } from "react";
import Home from "./pages/Home";
import OurPeople from "./pages/OurPeople";
import Blog from "./pages/Blog";
import ContactUs from "./pages/ContactUs";
import Profile from "./pages/Profile";
import Video from "./pages/Video";
import Podcast from "./pages/Podcast";
import CapitalProjects from "./pages/CapitalProjects";
import OperationalReadiness from "./pages/OperationalReadiness";
import Operations from "./pages/Operations";
import AllVideos from "./pages/AllVideos";
import AllBlogs from "./pages/AllBlogs";
import AllPodcasts from "./pages/AllPodcasts";
import VacanciesPage from "./pages/vacancies/index";
import VacancyPage from "./pages/vacancy/index";
import AdminPage from "./pages/admin/index";
import SearchGroup from "./pages/SearchGroup";
import Search from "./pages/Search";
import ReactGa from "react-ga";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useState } from "react";

function App({ pca }) {
  useEffect(() => {
    ReactGa.initialize("UA-207675567-1");
    //To report page view
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <MsalProvider instance={pca}>
      <Pages />
    </MsalProvider>
  );
  function Pages() {
    const loginRequest = {
      scopes: ["user.read"],
    };
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/ourPeople" component={OurPeople} />
          <Route exact path="/profile/:id" component={Profile} />
          <Route exact path="/capitalProjects" component={CapitalProjects} />
          <Route
            exact
            path="/operationalReadiness"
            component={OperationalReadiness}
          />
          <Route exact path="/operations" component={Operations} />
          <Route exact path="/allVideos" component={AllVideos} />
          <Route exact path="/allBlogs" component={AllBlogs} />
          <Route exact path="/allPodcasts" component={AllPodcasts} />
          <Route exact path="/video/:id" component={Video} />
          <Route exact path="/blog/:id" component={Blog} />
          <Route exact path="/podcast/:id" component={Podcast} />

          <Route exact path="/careers" component={VacanciesPage}></Route>

          <Route exact path="/vacancy/:id" component={VacancyPage}></Route>
          <Route exact path="/contactUs" component={ContactUs} />
          <Route exact path="/search-group" component={SearchGroup} />
          <Route exact path="/search" component={Search} />

          <Route exact path="/admin">
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={loginRequest}
            >
              {pca.getActiveAccount() == null ? (
                <AdminPage />
              ) : ![
                  "rob@cravern.com",
                  "lin.coloma@cravern.com",
                  "khatana@cravern.com",
                ].includes(pca.getActiveAccount().username) ? (
                <div
                  style={{
                    width: "100px",
                    height: "100vh",
                    display: "flex",
                    color: "white",
                    margin: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  Forbidden
                </div>
              ) : (
                <AdminPage />
              )}
            </MsalAuthenticationTemplate>
          </Route>

          <Route component={Error} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
