import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import { contents } from '../components/Services'
import HeaderTab from '../components/Header'
import FooterMain from '../components/Footer'
import '../assets/css/styles.css'
import './css/OperationalReadiness.css'

const content = [
  {
    title: 'Technology \n Scoping & \n Estimation',
    textTitle:'Technology \n Scoping & \n Estimation',
    text:
      'New operations being constructed have to be business ready by the time the construction is completed. This can involve the combined implementation of hundreds of business processes and the supporting software applications to enable all areas of the business. Having a defined set of business processes forms the basis for ongoing business improvement, systems implementation and organisational readiness can help a business overcome these problems and put in place an effective training and business improvement program from the outset.',
  },
  {
    title: 'Asset Management \n Systems Readiness',
    textTitle:'Asset Management \n Systems Readiness',
    
    text:
      'During the project there are often thousands of new equipment items that need to be setup within your reliability and CMMS. This sheer volume of the the work required in building maintenance tactics, maintenance plans, gathering equipment datasheets, BOMS and defining sparing strategies for this amount of equipment provides many projects with an enormous challenges in completing this prior to the commencement of commissioning activities.This is further complicated through poor, or unreliable, or late information coming from the project when preparing operational asset management systems.',
  },
  {
    title: 'Integrated \n Technology Design',
    textTitle:'Integrated \n Technology Design',
    text:
      'Most organisations now recognise how much value information technology can bring to the future operations by improving productivity and the quality of information supporting decisions. However, with the average operation typically needing to commission over 100 different systems during the project to support the future operational activities, most businesses are not sure how to successfully design their information system landscape.',
  },
  {
    title: 'Operational \n Technology \n Implementation',
    textTitle:'Operational \n Technology \n Implementation',
    text:
      'Most major projects treat technology scope as an after thought. Its common that technology budgets are not considered during program planning, and often the overall technology work has very poor planning. Most operations have over 100 different systems that need to be implemented to support the future operation, and the tie in with existing organisational systems can be considerable. Many of the technologies are needed during construction to support execution and there is an opportunity to minimise the frustration in transition from project into operation.',
  },
]

export default function OperationalReadiness() {
  const [clickIndex, setClickIndex] = useState(0)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <div className="background">
        <HeaderTab />
        <div className="container main-box">
          <div className="operational-readiness-home">
            <div className="container">
              <div className="description">Smooth Operational Readiness</div>
            </div>
            <Box className="service-flex">
              {contents(content, clickIndex, setClickIndex)}
            </Box>
          </div>
        </div>
        <FooterMain />
      </div>
    </React.Fragment>
  )
}
