import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API } from "../commons/commonMethods";
import ReactPlayer from "react-player/lazy";
import brandImage from "../assets/images/BrandImage.png";
import pdftoImage1 from "../assets/images/asop1.jpg";
import pdftoImage2 from "../assets/images/asop2.jpg";
import pdftoImage3 from "../assets/images/A_successful_operational_ramp_up.jpg";
import Services from "../components/Services";
import Content from "../components/Content";
import HeaderTab from "../components/Header";
import FooterMain from "../components/Footer";

import "../assets/css/styles.css";
import CookieConsent from "react-cookie-consent";

export default function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const [video, setVideo] = useState({});
  const [blog, setBlog] = useState({});
  const [blogImage, setBlogImage] = useState();
  const [podcast, setPodcast] = useState({});
  const getData = async () => {
    setIsLoading(true);
    API()
      .get("/home")
      .then((res) => {
        setVideo(res.data.video);
        setBlog(res.data.blog);
        setBlogImage(
          require(`../assets/images/blogs/${res.data.blog.imagePath}`)
        );
        setPodcast(res.data.podcast);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <React.Fragment>
      <div className="background">
        <HeaderTab />

        <div id="homepage">
          <div className="brand-image">
            <div className="image">
              <img src={brandImage} alt="Brand" />
              <img src={pdftoImage1} alt="Brand" width="0" height="0" />
              <img src={pdftoImage2} alt="Brand" width="0" height="0" />
              <img src={pdftoImage3} alt="Brand" width="0" height="0" />
            </div>
            <div className="brand-text-container">
              <div className="brand-text">
                <div className="title">
                  The Cravern Group are Industrial Project and Technology
                  Specialists
                </div>
                <div className="desc">
                  Explore how investment in information can provide
                  transformational benefits to your business.
                </div>
              </div>
            </div>
          </div>
          <div className="description">
            Improving Asset Intensive Businesses
          </div>
          <Services />
          <div className="description">Our Videos, Blogs and Podcasts</div>
          <div className="featured-container">
            {isLoading ? (
              <div>Loading ...</div>
            ) : (
              <React.Fragment>
                <div className="flex-item">
                  <div className="more">
                    <Link className="more" to={`/allBlogs/`}>
                      See All Blogs
                    </Link>
                  </div>
                  <div className="reactPlayer-container">
                    {blog && blog.imagePath && (
                      <Link className="more" to={`/blog/${blog.id}`}>
                        <img
                          src={blogImage}
                          className="reactPlayer"
                          alt={blog.title}
                        />
                      </Link>
                    )}
                  </div>

                  <span className="blogText">
                    <Link className="more" to={`/blog/${blog.id}`}>
                      <div className="title">{blog.title}</div>
                    </Link>
                    <Content content={blog.firstContent}></Content>
                    <Link className="more" to={`/blog/${blog.id}`}>
                      <a className="title">See more</a>
                    </Link>
                  </span>
                </div>

                <div className="flex-item">
                  <div className="more">
                    <Link className="more" to={`/AllPodcasts/`}>
                      See All Podcasts
                    </Link>
                  </div>
                  <div className="reactPlayer-container">
                    {podcast && podcast.audioPath && (
                      <ReactPlayer
                        url={podcast.audioPath}
                        className="reactPlayer"
                        width="100%"
                        height="auto"
                        controls
                        pip
                      />
                    )}
                  </div>
                  <span className="blogText">
                    <Link className="more" to={`/podcast/${podcast.id}`}>
                      <div className="title">{podcast.title}</div>
                    </Link>
                    <Content content={podcast.content} />
                  </span>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <CookieConsent>
          This website uses cookies to enhance the user experience.
        </CookieConsent>

        <FooterMain />
      </div>
    </React.Fragment>
  );
}
