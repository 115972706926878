import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderTab from "../../components/Header";
import FooterMain from "../../components/Footer";
import Content from "../../components/Content";
import { API } from "../../commons/commonMethods";
import emailjs from "emailjs-com";
import { contentType } from "../../commons/enum";
import Box from "@material-ui/core/Box";
import "../../assets/css/styles.css";
import "./../css/Career.css";
import { FormLabel, Grid } from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";
import { useForm, Form } from "../../components/useForm";
import Controls from "../../components/controls/Controls";
import {
  IoBriefcaseSharp,
  IoCalendarSharp,
  IoLocationSharp,
} from "react-icons/io5";
import Modal from "react-modal";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";

const codeOptions = [
  { id: "61", title: "+61" },
  { id: "976", title: "+976" },
];
const surveyJson = {
  questionErrorLocation: "bottom",
  logoHeight: "100px",
  logoFit: "cover",
  logoPosition: "right",
  elements: [
    {
      type: "panel",
      name: "personal-info",
      title: "Personal Information",
      elements: [
        {
          type: "text",
          name: "full-name",
          title: "Full name",
          isRequired: true,
        },
        {
          type: "text",
          name: "birthdate",
          title: "Date of birth",
          inputType: "date",
          isRequired: true,
        },
      ],
    },
    {
      type: "panel",
      name: "location",
      title: "Your Location",
      elements: [
        {
          type: "dropdown",
          name: "country",
          title: "Country",
          isRequired: true,
          choicesByUrl: {
            url: "https://surveyjs.io/api/CountriesExample",
          },
        },
        {
          type: "text",
          name: "city",
          title: "City/Town",
          isRequired: true,
        },
      ],
    },
    {
      type: "text",
      name: "email",
      title: "Email",
      inputType: "email",
      placeholder: "mail@example.com",
      isRequired: true,
    },
    {
      type: "text",
      name: "start-date",
      title: "Date available to start work",
      isRequired: true,
      inputType: "date",
    },
    {
      type: "file",
      name: "resume",
      title: "Upload your resume",
      isRequired: true,
      acceptedTypes: "application/pdf",
    },
  ],
  showQuestionNumbers: false,
  completeText: "Send",
  widthMode: "static",
  width: "600px",
};

Modal.setAppElement("#root");

export default function VacancyPage() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [applicationStatus, setApplicationStatus] = useState(undefined);
  const [career, setCareer] = useState({});
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [customStyles, setStyles] = useState({
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: 600,
      height: "80%",
      background: "rgb(243,243,243)",
    },
  });

  const survey = new Model(surveyJson);
  survey.showCompletedPage = false;
  survey.onCompleting.add(async (sender, options) => {
    setStyles({
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        background: "rgb(243,243,243)",
      },
    });
    setApplicationStatus("Uploading...");
    //await new Promise((resolve) => setTimeout(resolve, 5000));
    applyOnline(sender.data);
  });

  function afterOpenModal() {}
  function openModal() {
    document.body.style.overflowY = "hidden";
    setIsOpen(true);
  }
  function closeModal() {
    document.body.style.overflowY = "auto";
    if (applicationStatus == "Failed") {
      setApplicationStatus(undefined);
    }
    setIsOpen(false);
  }

  const applyOnline = async (formData) => {
    try {
      const response = await API().post(`/jobApplication/apply`, formData);
      if (response.status === 200) {
        setApplicationStatus("Success");
      } else {
        setApplicationStatus("Failed");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : "Please enter your email!";
    }
    if ("phoneNumber" in fieldValues) {
      temp.phoneNumber = fieldValues.phoneNumber
        ? ""
        : "Please input your phone number!";
    }
    if ("coverLetter" in fieldValues) {
      temp.coverLetter = fieldValues.coverLetter
        ? ""
        : "Please type your cover letter!";
    }
    if ("resume" in fieldValues) {
      temp.resume = fieldValues.resume ? "" : "Please attach your resume!";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    {
      email: "",
      callingCode: "",
      phoneNumber: "",
      coverLetter: "",
      resume: null,
    },
    true,
    validate
  );

  const getData = async () => {
    try {
      setIsLoading(true);
      API()
        .get(`/${contentType.CAREER}/${id}`)
        .then((res) => {
          setCareer(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    API()
      .post("/career", {
        id: id,
        ...values,
        date: JSON.stringify(new Date()).replace(/\"/g, ""),
      })
      .then((res) => {
        console.log("message => ", res.data.message);
        resetForm();
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        resetForm();
        setIsLoading(false);
      });
    let templateParams = {
      from_name: values.name,
      message: values.coverLetter,
      to_name: "Turbold",
      career_email: values.email,
      area_code: values.callingCode,
      phoneNum: values.phoneNumber,
    };
    emailjs
      .send(
        "service_5lkw6jb",
        "template_ahp03lm",
        templateParams,
        "user_8Su9Rqw34eBKZTERYgCJP"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };

  return (
    <React.Fragment>
      <div className="background">
        <HeaderTab />

        {isLoading ? (
          <div>Loading ...</div>
        ) : (
          <div className="container main-box career">
            <div className="title">{career.title}</div>
            <div className="date">
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "short",
                day: "2-digit",
              }).format(new Date(career.date ? career.date : new Date()))}
            </div>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="flex-end"
              color="#3d838f"
              className="detail"
              my={3}
            >
              <Box flex="1 1 20%" maxWidth="330px" minWidth="250px">
                <div className="jobType">
                  <IoBriefcaseSharp />
                  <span>{career.type}</span>
                </div>
              </Box>
              <Box flex="1 1 20%" maxWidth="330px" minWidth="250px">
                <div className="location">
                  <IoLocationSharp />
                  <span>{career.location}</span>
                </div>
              </Box>
              <Box flex="1 1 16%" maxWidth="270px" minWidth="250px">
                <div className="date">
                  <IoCalendarSharp />
                  <span>
                    {new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    }).format(new Date(career.date ? career.date : new Date()))}
                  </span>
                </div>
              </Box>
            </Box>

            <Content content={career.content} />
            <Modal
              shouldCloseOnOverlayClick={true}
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              {applicationStatus == undefined ? (
                <Survey model={survey} />
              ) : (
                <div>
                  <div
                    style={{
                      width: 80,
                      fontWeight: "bold",
                      color: "rgba(0,0,0,0.66)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span>{applicationStatus}</span>
                  </div>
                  {applicationStatus == "Uploading..." ? null : (
                    <div>
                      <button
                        onClick={() => {
                          closeModal();
                        }}
                        style={{
                          borderRadius: 3,
                          width: "100%",
                          marginTop: 10,
                          backgroundColor: "#273e5e",
                          padding: 8,
                          color: "white",
                          fontFamily: "Graphik Regular",
                          fontSize: 15,
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  )}
                </div>
              )}
            </Modal>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                onClick={() => {
                  openModal();
                }}
                style={{
                  borderRadius: 3,
                  width: 120,
                  height: 40,
                  marginTop: 10,
                  backgroundColor: "#273e5e",
                  padding: 8,
                  color: "white",
                  fontFamily: "Graphik Regular",
                  fontSize: 15,
                }}
              >
                Apply Online
              </button>
            </div>
          </div>
        )}
        <FooterMain />
      </div>
    </React.Fragment>
  );
}
