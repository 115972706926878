import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player/lazy'
import HeaderTab from '../components/Header'
import FooterMain from '../components/Footer'
import Comment from '../components/Comment'
import Content from '../components/Content'
import { API } from '../commons/commonMethods'
import { contentType } from '../commons/enum'
import Box from '@material-ui/core/Box'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import '../assets/css/styles.css'
import './css/Podcast.css'
import './css/RightContent.css'

export default function Podcast() {
  const iPadWidth = useMediaQuery('(min-width:800px)')
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [podcast, setPodcast] = useState({})
  const [podcasts, setPodcasts] = useState({})
  const [newComment, setNewComment] = useState({})
  const getData = async () => {
    try {
      setIsLoading(true)
      API()
        .get(`/${contentType.PODCAST}`)
        .then((res) => {
          let data = res.data
          setPodcasts(data)
          setPodcast(data.length > 0 ? data.find((item) => item.id === id) : {})
          setIsLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [id])

  useEffect(() => {
    getData()
  }, [newComment])

  const guestList = (list) => {
    return (
      list &&
      list.map((guest, index) => {
        return (
          <div className="authorItem" key={index}>
            <img
              className="authorImage"
              src={
                require(`../assets/images/guests/${guest.imagePath}`).default
              }
              alt={guest.name}
            />
            <div className="name">{guest.name}</div>
          </div>
        )
      })
    )
  }

  const postList = (posts) => {
    return (
      posts &&
      posts.map((post, index) => {
        return (
          <div className="recentPostItem" key={index}>
            <Link to={`/podcast/${post.id}`}>
              <div className="title">{post.title}</div>
              <div className="date">
                {new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: '2-digit',
                }).format(new Date(post.postDate ? post.postDate : new Date()))}
              </div>
            </Link>
          </div>
        )
      })
    )
  }

  return (
    <React.Fragment>
      <div className="background">
        <HeaderTab />

        <div className="container main-box podcast">
          {isLoading ? (
            <div>Loading ...</div>
          ) : (
            Object.keys(podcast).length > 0 && (
              <Box display="flex">
                <Box className="content-box main-content">
                  <div className="date">
                    {new Intl.DateTimeFormat('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit',
                    }).format(
                      new Date(podcast.date ? podcast.date : new Date()),
                    )}
                  </div>
                  <div className="title">{podcast.title}</div>
                  <ReactPlayer
                    url={podcast.audioPath}
                    className="reactPlayer"
                    width="100%"
                    height="auto"
                    controls
                    pip
                  />
                  <Content content={podcast.content} />
                  <Comment
                    comments={podcast.comments ? podcast.comments : []}
                    type={contentType.PODCAST}
                    id={podcast.id}
                    setNewComment={setNewComment}
                  />
                </Box>
                {iPadWidth && (
                  <Box className="side-box" px={2}>
                    <div className="author">
                      <div className="header">GUESTS</div>
                      {guestList(podcast.guests ? podcast.guests : [])}
                    </div>
                    <div className="recentPosts">
                      <div className="header">RECENT PODCASTS</div>
                      {postList(podcasts)}
                    </div>
                  </Box>
                )}
              </Box>
            )
          )}
        </div>
        <FooterMain />
      </div>
    </React.Fragment>
  )
}
