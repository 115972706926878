import React, { useState } from "react";
import "../assets/css/styles.css";
import "./css/ContactUs.css";
import HeaderTab from "../components/Header";
import FooterMain from "../components/Footer";
import { API } from "../commons/commonMethods";
import Controls from "../components/controls/Controls";
import { useForm, Form } from "../components/useForm";
import Box from "@material-ui/core/Box";
import map from "../assets/images/WorldMap.png";

export default function ContactUs() {
  const [isLoading, setIsLoading] = useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues) {
      temp.name = fieldValues.anme ? "" : "Please enter your name.";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    { name: "", email: "", message: "" },
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    API()
      .post("/contact", {
        ...values,
        date: JSON.stringify(new Date()).replace(/\"/g, ""),
      })
      .then((res) => {
        console.log("message => ", res.data.message);
        resetForm();
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        resetForm();
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="background">
        <HeaderTab />

        <div className="container main-box" id="ContactUs">
          <article>
            <img className="map" src={map} alt="map" />
            <Box className="service-flex" my={2} px="3vw">
              <Box className="service-box left" mx={2}>
                <div className="title">The Cravern Group</div>
                <div className="content">
                  <p>The Cravern Group operates across the Globe.</p>
                  <p>
                    Brisbane and Perth are joint headquarters, as well as
                    satellite offices in Denver and Mongolia.
                  </p>
                  <p>
                    If you’d like to get in touch, contact us via our contact
                    form or email{" "}
                    <a href="mailto:admin@cravern.com">admin@cravern.com</a>.
                  </p>
                </div>
              </Box>
              <Box className="service-box form" mx={2}>
                <div className="title">Get in touch</div>
                <Form className="search-form" onSubmit={handleSubmit}>
                  <Box className="service-flex">
                    <Box className="form-box" mb={2} mr={1}>
                      <Controls.Input
                        name="name"
                        value={values.name}
                        placeholder="Name"
                        variant="filled"
                        fullWidth
                        size="small"
                        onChange={handleInputChange}
                      />
                    </Box>
                    <Box className="form-box" mb={2} ml={1}>
                      <Controls.Input
                        name="email"
                        value={values.email}
                        placeholder="Email"
                        variant="filled"
                        fullWidth
                        size="small"
                        onChange={handleInputChange}
                      />
                    </Box>
                    <Box className="form-box-full" mb={2}>
                      <Controls.TextArea
                        name="message"
                        value={values.message}
                        placeholder="Message"
                        variant="filled"
                        fullWidth
                        onChange={handleInputChange}
                      />
                    </Box>
                  </Box>
                  <Controls.Button
                    type="message"
                    className="send"
                    text="SEND"
                  />
                </Form>
              </Box>
            </Box>
          </article>
        </div>
        <FooterMain />
      </div>
    </React.Fragment>
  );
}
