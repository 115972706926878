import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import HeaderTab from '../components/Header'
import FooterMain from '../components/Footer'
import SearchHeader from '../components/SearchHeader'
import { API, useQuery } from '../commons/commonMethods'
import '../assets/css/styles.css'
import './css/SearchGroup.css'

const defaultCheckedList = ['video', 'blog', 'podcast', 'career', 'ourPeople']

const groupHeader = {
  video: 'Video',
  blog: 'Blog',
  podcast: 'Podcast',
  career: 'Career',
  ourPeople: 'People',
}

const linkProperties = {
  video: { text: 'All Video', allUrl: '/allVideos', contentUrl: '/video/' },
  blog: { text: 'All Blog', allUrl: '/allBlogs', contentUrl: '/blog/' },
  podcast: {
    text: 'All Podcast',
    allUrl: '/allPodcasts',
    contentUrl: '/podcast/',
  },
  career: { text: 'Careers', allUrl: '/careers', contentUrl: '/career/' },
  ourPeople: {
    text: 'Our People',
    allUrl: '/ourPeople',
    contentUrl: '/profile/',
  },
}

export default function SearchGroup() {
  const query = useQuery()
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = React.useState({})

  const getData = async () => {
    try {
      setIsLoading(true)
      const values = {
        keyword: query.get('keyword'),
        areas: defaultCheckedList,
      }
      API()
        .post('/search', values)
        .then((res) => {
          setResult(res.data)
          setIsLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  const groupList = (list) => {
    return list.map((item, index) => {
      return (
        <article key={`search-group-${index}`}>
          <section className="groupHeader">{groupHeader[item]}</section>
          {detailList(result[item], item)}
          <section className="groupFooter">
            <Link to={`/search?type=${item}&keyword=${query.get('keyword')}`}>
              See all {groupHeader[item].toLowerCase()} results
            </Link>
          </section>
        </article>
      )
    })
  }

  const detailList = (list, type) => {
    return list.map((item, index) => {
      return (
        <article className="search-content" key={`search-detail-${index}`}>
          <Link to={linkProperties[type].contentUrl + item.id}>
            <section className="title">{item.title}</section>
            <section className="content">{item.content}</section>
          </Link>
          {links(type, item.id, item.title)}
        </article>
      )
    })
  }

  const links = (type, id, title) => {
    return (
      <React.Fragment>
        <Link to={linkProperties[type].allUrl}>
          {linkProperties[type].text}
        </Link>
        {' > '}
        <Link to={linkProperties[type].contentUrl + id}>{title}</Link>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className="background">
        <HeaderTab />
        <div className="container main-box searchGroup">
          {isLoading ? (
            <div>Loading ...</div>
          ) : (
            <div className="main-content">
              <SearchHeader
                keyword={query.get('keyword')}
                type={'group'}
                setResult={setResult}
              />
              {groupList(Object.keys(result))}
            </div>
          )}
        </div>
        <FooterMain />
      </div>
    </React.Fragment>
  )
}
