import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ReactPlayer from 'react-player/lazy'
import HeaderTab from '../components/Header'
import FooterMain from '../components/Footer'
import Comment from '../components/Comment'
import Content from '../components/Content'
import { API } from '../commons/commonMethods'
import { contentType } from '../commons/enum'
import Box from '@material-ui/core/Box'
import '../assets/css/styles.css'
import './css/Video.css'

export default function Video() {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [video, setVideo] = useState({})
  const [newComment, setNewComment] = useState({})
  const getData = async () => {
    try {
      setIsLoading(true)
      API()
        .get(`/${contentType.VIDEO}/${id}`)
        .then((res) => {
          setVideo(res.data)
          setIsLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [id])

  useEffect(() => {
    getData()
  }, [newComment])

  return (
    <React.Fragment>
      <div className="background">
        <HeaderTab />

        <div className="container main-box video">
          {isLoading ? (
            <div>Loading ...</div>
          ) : (
            Object.keys(video).length > 0 && (
              <Box display="flex">
                <Box className="content-box main-content" px={8} py={4}>
                  <div className="date">
                    {new Intl.DateTimeFormat('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit',
                    }).format(new Date(video.date ? video.date : new Date()))}
                  </div>
                  <div className="title">{video.title}</div>
                  <ReactPlayer
                    url={video.videoPath}
                    className="reactPlayer"
                    width="100%"
                    height="auto"
                    controls
                    pip
                  />
                  <Content content={video.content} />
                  <Comment
                    comments={video.comments ? video.comments : []}
                    type={contentType.VIDEO}
                    id={video.id}
                    setNewComment={setNewComment}
                  />
                </Box>
              </Box>
            )
          )}
        </div>
        <FooterMain />
      </div>
    </React.Fragment>
  )
}
