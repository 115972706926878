import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import HeaderTab from "../components/Header";
import FooterMain from "../components/Footer";
import Comment from "../components/Comment";
import Content from "../components/Content";
import { API } from "../commons/commonMethods";
import { contentType } from "../commons/enum";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "../assets/css/styles.css";
import "./css/Blog.css";
import "./css/RightContent.css";

export default function Blog() {
  const iPadWidth = useMediaQuery("(min-width:800px)");
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [blog, setBlog] = useState({});
  const [blogs, setBlogs] = useState([]);
  const [newComment, setNewComment] = useState({});
  const getData = async () => {
    try {
      setIsLoading(true);
      API()
        .get(`/${contentType.BLOG}`)
        .then((res) => {
          let data = res.data;
          setBlogs(data);
          setBlog(data.length > 0 ? data.find((item) => item.id === id) : {});
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    getData();
  }, [newComment]);

  const authorList = (list) => {
    return (
      list &&
      list.map((author, index) => {
        return (
          <div className="authorItem" key={index}>
            <img
              className="authorImage"
              src={
                require(`../assets/images/authors/${author.imagePath}`).default
              }
              alt={author.name}
            />
            <div className="name">{author.name}</div>
          </div>
        );
      })
    );
  };

  const postList = (posts) => {
    return (
      posts &&
      posts.map((post, index) => {
        return (
          <div className="recentPostItem" key={index}>
            <Link to={`/blog/${post.id}`}>
              <div className="title">{post.title}</div>
              <div className="date">
                {new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                }).format(
                  new Date(post.postDate ? new Date(post.postDate) : new Date())
                )}
              </div>
            </Link>
          </div>
        );
      })
    );
  };

  return (
    <React.Fragment>
      <div className="background">
        <HeaderTab />

        <div className="container main-box blog-page">
          {isLoading ? (
            <div>Loading ...</div>
          ) : (
            Object.keys(blog).length > 0 && (
              <Box display="flex">
                <Box className="content-box main-content">
                  <div className="date">
                    {new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                    }).format(
                      new Date(
                        blog.postDate ? new Date(blog.postDate) : new Date()
                      )
                    )}
                  </div>
                  <div className="title">{blog.title}</div>
                  <img
                    src={
                      require(`../assets/images/blogs/${blog.imagePath}`)
                        .default
                    }
                    alt={blog.title}
                  />
                  <Content content={blog.content} />
                  <Comment
                    comments={blog.comments ? blog.comments : []}
                    type={contentType.BLOG}
                    id={blog.id}
                    setNewComment={setNewComment}
                  />
                </Box>
                {iPadWidth && (
                  <Box className="side-box" px={2}>
                    <div className="author">
                      <div className="header">Authors</div>
                      {authorList(blog.authors ? blog.authors : [])}
                    </div>
                    <div className="recentPosts">
                      <div className="header">RECENT POSTS</div>
                      {postList(blogs)}
                    </div>
                  </Box>
                )}
              </Box>
            )
          )}
        </div>
        <FooterMain />
      </div>
    </React.Fragment>
  );
}
