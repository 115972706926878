import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import HeaderTab from "../../components/Header";
import FooterMain from "../../components/Footer";
import { Link } from "react-router-dom";
import { API } from "../../commons/commonMethods";
import "../../assets/css/styles.css";
import "./../css/Careers.css";
import Modal from "react-modal";

export default function AdminPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [careers, setCareers] = useState([]);
  const [currentResume, setCurrentResume] = useState({ type: "", content: "" });
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [customStyles] = useState({
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      height: "80%",
    },
  });

  function afterOpenModal() {}
  function openModal() {
    document.body.style.overflowY = "hidden";
    setIsOpen(true);
  }
  function closeModal() {
    document.body.style.overflowY = "auto";
    setIsOpen(false);
  }

  const getData = async () => {
    try {
      setIsLoading(true);
      API()
        .post(`/jobApplication/list`)
        .then((res) => {
          setCareers(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const boxStyle = {
    border: "1px solid #dadada",
    borderRadius: "3px",
    color: "#3d838f",
    margin: "auto 5px",
    padding: "0 4px 0 4px",
  };

  const careerList = (list) => {
    return list.map((career, index) => {
      return (
        <div key={index} style={{ display: "flex" }}>
          <Box
            alignItems="center"
            justifyContent={"center"}
            border="1px solid #dadada"
            style={boxStyle}
            key={1}
          >
            {career["full-name"]}
          </Box>
          <Box
            alignItems="center"
            border="1px solid #dadada"
            style={boxStyle}
            key={2}
          >
            {career["city"]}
          </Box>
          <Box
            alignItems="center"
            border="1px solid #dadada"
            style={boxStyle}
            key={2}
          >
            {career["country"]}
          </Box>
          <Box
            alignItems="center"
            border="1px solid #dadada"
            style={boxStyle}
            key={2}
          >
            {career["email"]}
          </Box>
          <Box
            alignItems="center"
            border="1px solid #dadada"
            style={{
              border: "1px solid #dadada",
              borderRadius: "3px",
              color: "#3d838f",
              margin: "5px 5px",
              padding: "0 4px 0 4px",
            }}
            key={2}
          >
            <span>Birth Date:</span>
            <br />
            <span>{career["birthdate"]}</span>
          </Box>
          <Box
            alignItems="center"
            border="1px solid #dadada"
            style={{
              border: "1px solid #dadada",
              borderRadius: "3px",
              color: "#3d838f",
              margin: "5px 5px",
              padding: "0 4px 0 4px",
            }}
            key={2}
          >
            <span>Start Date:</span>
            <br />
            <span>{career["start-date"]}</span>
          </Box>
          <Box
            alignItems="center"
            border="1px solid #dadada"
            style={{ ...boxStyle, cursor: "pointer" }}
            onClick={() => {
              if (career.resume && career.resume.length > 0) {
                setCurrentResume(career.resume[0]);
                openModal();
              }
            }}
            key={2}
          >
            <span>Resume:</span>
            <br />
            <span>
              {career.resume
                ? career.resume.length > 0
                  ? career.resume[0].name
                  : ""
                : ""}
            </span>
          </Box>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <div className="background">
        <HeaderTab />

        <div className="container careers">
          {isLoading ? (
            <div>Loading ...</div>
          ) : (
            <React.Fragment>
              <div className="main-box" style={{ minHeight: 1200 }}>
                <h1 className="title">
                  <span>Current applications</span>
                </h1>
                <div style={{ display: "flex" }}>
                  <div>{careerList(careers)}</div>

                  <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <div style={{ width: "100%", height: "100%" }}>
                      <embed
                        style={{ width: "100%", height: "100%" }}
                        src={`${currentResume.content}`}
                      ></embed>
                    </div>
                  </Modal>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        <FooterMain />
      </div>
    </React.Fragment>
  );
}
