import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/styles.css";
import "./css/Footer.css";

export default function Footer() {
  return (
    <React.Fragment>
      <div className="footer">
        <a href="https://www.linkedin.com/company/the-cravern-group">
          LinkedIn
        </a>
        <a href="mailto:rob@cravern.com">Email</a>
        <Link to={"/contactUs"}>Contact Us</Link>
      </div>
    </React.Fragment>
  );
}
