import React from 'react';
import '../assets/css/styles.css';

const itemList = list => {
  return list.map((item, index) => {
    return <li style={{"listStyleType": "circle"}} key={`list-${index}`}>{item}</li>;
  });
};

const contentList = list => {
  return (
    list &&
    list.length > 0 &&
    list.map((content, index) => {
      return <React.Fragment key={`content-${index}`}>{renderContent(content)}</React.Fragment>;
    })
  );
};

function renderContent(content) {
  switch (content.type) {
    case 'paragraph':
      return <p style={content.properties}>{content.content}</p>;
    case 'image':
      return <img style={content.properties} src={require(`../assets/images/blogs/${content.path}`).default} alt={content.path} />;
    case 'list':
      return <ul style={content.properties}>{itemList(content.content)}</ul>;
    default:
      return <></>;
  }
}

export default function Content({ content }) {
  return <div className="content">{contentList(content)}</div>;
}
